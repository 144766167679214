import { Link, navigate } from 'gatsby';
import React, { FunctionComponent, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { FabricaFormulari } from './Formulari';
import { usaUsuari } from './Usuari';
import { useLocation } from '@reach/router';
import { Alert } from 'react-bootstrap';
import { mostraUnAvís } from './Avisos';

type ParàmetresPerCanviarLaContrasenya = { contrasenya: string };

const { Formulari, Camp, BotóDEnviar } = FabricaFormulari<ParàmetresPerCanviarLaContrasenya>();

export const CanviarLaContrasenya: FunctionComponent<{}> = () => {
  const { canviaLaContrasenya } = usaUsuari();
  const { search } = useLocation();
  const [intentat, posaIntentat] = useState(false);

  async function enEnviar({ contrasenya }: ParàmetresPerCanviarLaContrasenya) {
    const params = new URLSearchParams(search);

    const èxit = await canviaLaContrasenya(contrasenya, params.get('testimoni') || '');
    if (èxit) {
      mostraUnAvís({ títol: 'Contrasenya canviada.', variant: 'success' });
      void navigate('/socis');
    } else {
      posaIntentat(true);
    }

    return {};
  }

  return (
    <Formulari envia={enEnviar}>
      {intentat && <Alert variant="warning">L'enllaç ha caducat. Hauras de tornar a <Link to="/recuperar-contrasenya">començar el procés</Link>.</Alert>}
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Nova contrasenya</Form.Label>
          <Camp nom="contrasenya" obligatori tipus="password" ocupant="Nova contrasenya"/>
        </Form.Group>
        <BotóDEnviar>
          Canviar contrasenya
        </BotóDEnviar>
      </Stack>
    </Formulari>
  );
};
